import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import Section from "./Section";
import useSiteSettings from "../utils/useSiteSettings";
import { useStaticQuery } from "gatsby";
// import { Logo, LogoFull } from "./Svg";
import Logo from "./Logo";
import { Link } from "gatsby";
import { mobile, tablet, desktop, desktopUp } from "../styles/global";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";
import { useSiteState } from "../context/siteContext";
import useBreakpoint from "../utils/useBreakpoint";
import useWindow from "../utils/useWindow";
import BlockText from "./BlockText";
import HoverLogo from "./HoverLogo";

const Header = ({ className, scrolled }) => {
	const [menuOpen, setmenuOpen] = useState(false);
	const { address, email, tel, instagramUrl } = useSiteSettings();
	const [siteState, setSiteState] = useSiteState();
	const location = useLocation();
	const { isMobile } = useBreakpoint();

	const isHome = location?.pathname === "/" || location.pathname === "";
	const isProjects =
		location.pathname === "/projects" || location.pathname === "/projects/";
	const isArchive = location?.pathname.includes("/archive");

	const [currentPage, setCurrentPage] = useState();

	useEffect(() => {
		setCurrentPage(location.pathname);
	}, [location]);

	const headerRef = useRef();

	const container = {
		closed: {
			opacity: 0,
			transition: {
				duration: 0.25,
			},
		},
		open: {
			opacity: 1,
			transition: {
				type: "tween",
			},
		},
	};

	const pages = [
		{ title: "Projects", slug: "/projects" },
		{ title: "Process", slug: "/process" },
		{ title: "Practice", slug: "/practice" },
		{ title: "Archive", slug: "/archive" },
	];

	const [fullHeight, setFullHeight] = useState();

	useLayoutEffect(() => {
		if (window.innerWidth <= 768) {
			setFullHeight(window.innerHeight + "px");
		}
	}, []);

	return (
		<>
			<Wrap
				ref={headerRef}
				className={`header${isProjects ? "-projects" : ""}${
					isHome ? "-home" : ""
				}${isArchive ? "-archive" : ""}`}
				isProjects={isProjects}
				isArchive={isArchive}
				css={css`
					${mobile} {
						height: ${menuOpen ? fullHeight : "auto"};
						background-color: ${menuOpen ? "var(--white)" : "transparent"};
					}
				`}
			>
				<DesktopWrap
					className={`header${isProjects ? "-projects" : ""}${
						isHome ? "-home" : ""
					}${isArchive ? "-archive" : ""}`}
					scrolled={scrolled}
					css={css`
						&.header-home,
						&.header-projects,
						&.header-archive {
							background-color: var(--white);
							border-bottom: ${menuOpen
								? "1px solid transparent"
								: "var(--border-grey)"};
						}
					`}
				>
					<LogoWrap>
						<LogoInner>
							<Logo
								css={css`
									opacity: ${siteState.entered ? 1 : 0};
									transition: opacity 0s linear 500ms;
								`}
							/>
						</LogoInner>
					</LogoWrap>
					<NavDesktop className="nav-desktop">
						{pages.map((page) => {
							return (
								<a
									key={`nav-${page.slug}`}
									href={page.slug}
									className={currentPage?.includes(page.slug) ? "active" : ""}
								>
									{page.title}
								</a>
							);
						})}
					</NavDesktop>
					<MenuToggle
						onClick={() => {
							setmenuOpen(!menuOpen);
						}}
					>
						{menuOpen ? "Close" : "Menu"}
					</MenuToggle>
				</DesktopWrap>
				<MenuMobile
					layout
					initial={false}
					variants={container}
					animate={menuOpen ? "open" : "closed"}
					css={css`
						pointer-events: ${menuOpen ? "all" : "none"};
					`}
				>
					<NavMobile>
						<Link to="/projects">Projects</Link>
						<Link to="/process">Process</Link>
						<Link to="/practice">Practice</Link>
						<Link to="/archive">Archive</Link>
					</NavMobile>
					<FooterMobile>
						<MobileAddress value={address} />
						<MobileContact>
							<a
								href={`mailto:${email}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{email}
							</a>
							<a href={`tel:${tel}`} target="_blank" rel="noopener noreferrer">
								{tel}
							</a>
						</MobileContact>
						<MobileIG
							target="_blank"
							rel="noopener noreferrer"
							href={instagramUrl}
						>
							Follow us
							<br />
							Instagram
						</MobileIG>
					</FooterMobile>
				</MenuMobile>
			</Wrap>
		</>
	);
};

const Wrap = styled.header`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	height: var(--header-height);
	font-family: var(--hermes);
	font-size: var(--accent-size);
	line-height: var(--accent-line);
	letter-spacing: var(--accent-letter);
	display: flex;
	flex-direction: column;

	${mobile} {
		height: auto;
	}

	.nav-desktop {
	}
`;

const DesktopWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--header-height);
	padding: 0 var(--padding);
	// z-index: 100;
	background-color: var(--header-bg);
	// border-bottom: 1px solid var(--header-border);
	transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
	color: var(--fg);
	// mix-blend-mode: difference;

	svg path {
		fill: var(--fg);
	}

	${mobile} {
		color: var(--fg);
	}
`;

const LogoWrap = styled.div`
	height: 12px;
	width: 205px;
	position: relative;

	svg {
		transform: unset !important;
	}
`;

const LogoInner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	isolation: isolate;
`;

const NavDesktop = styled.nav`
	display: flex;
	gap: 12px;
	text-transform: capitalize;
	transition: opacity var(--transition-300);

	&:has(.active) {
		a {
			opacity: 0.5;

			&.active {
				opacity: 1;
			}
		}
	}

	@media (hover: hover) {
		&:hover > :not(:hover) {
			opacity: 0.5 !important;
		}

		a:hover {
			opacity: 1;
		}
	}

	${mobile} {
		display: none;
	}
`;

const MenuToggle = styled.button`
	height: min-content;
	display: none;

	${mobile} {
		display: block;
	}
`;

const MenuMobile = styled(motion.div)`
	// flex: 1;
	display: none;
	position: fixed;
	margin-top: -1px;
	// inset: 0;
	top: var(--header-height);
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0 var(--padding) 15px var(--padding);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: var(--marfa);
	font-size: var(--body-size);
	line-height: var(--body-line);
	letter-spacing: 0;
	background-color: var(--white);

	${mobile} {
		display: flex;
	}
`;

const NavMobile = styled.nav`
	${mobile} {
		display: flex;
		flex-direction: column;

		> a {
			border-top: var(--border-black);
			padding: 15px 0;

			&:last-child {
				border-bottom: var(--border-black);
			}
		}
	}
`;

const FooterMobile = styled.footer`
	> * {
		margin-top: var(--body-line);
	}

	a {
		display: block;
	}
`;

const MobileAddress = styled(BlockText)``;

const MobileContact = styled.div``;

const MobileIG = styled.a``;

export default Header;
